import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Changed from useHistory
import { AuthContext } from '../../../components/Auth/AuthContext/AuthContext';

const SignOut: React.FC = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate(); // Changed from useHistory

  useEffect(() => {
    const signOut = async () => {
      await logout();
      navigate('/'); // Changed from history.push
    };
    signOut();
  }, [logout, navigate]); // Updated dependency array

  return <div>Signing out...</div>;
};

export default SignOut;