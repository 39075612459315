import React from 'react';
import CoralChart from '../CoralChart/CoralChart';
import { Loader } from 'lucide-react';
import { useEcosystemData } from '../../hooks/useEcosystemData';

interface EcosystemVisualProps {
  width?: number;
  height?: number;
}

const EcosystemVisual: React.FC<EcosystemVisualProps> = ({ 
  width = 300, 
  height = 300 
}) => {
  const { data, isLoading, error } = useEcosystemData();

  if (isLoading) {
    return <Loader className="w-8 h-8 text-coral-pink animate-spin" />;
  }

  if (error) {
    return null;
  }

  return (
    <CoralChart
      width={width}
      height={height}
      data={data}
      showAmounts={false}
    />
  );
};

export default EcosystemVisual;
