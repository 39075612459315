import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// import coralReef from '../../assets/images/coral-reef.svg';
import blueWaveImage from '../../../assets/images/blue-wave.png';
import darkBlueWaveImage from '../../../assets/images/dark-blue-wave.png';
import { faqData } from '../Home/faqData';
// import Spinner from '../../../components/Spinner/Spinner';
import { storeReferralUrl } from '../../../utils/referralUtils';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
// import useCoralEditor from '../../hooks/useCoralEditor';
import AllInOneCoralCreator from '../../../components/AllInOneCoralCreator/AllInOneCoralCreator';
import FAQItem from '../../../components/FAQItem/faqItem';
import CollectiveImpactCalculator from '../../../components/CollectiveImpactTable/CollectiveImpactCalculator';
import CoralEcosystem from '../../../components/CoralEcosystem/CoralEcosystem';
import { Tooltip } from 'react-tooltip';
import CoralButton from '../../../components/CoralButtons/CoralButton';
import { ClientCoral } from 'shared/types/platformTypes';
import mainBackgroundImage from '../../../assets/images/coral-reef-black.svg';
import RecentCoralVisualisation from '../../../components/RecentCoralVisualisation/RecentCoralVisualisation';
import MetaHead from '../../../components/MetaHead/MetaHead';

const PublicCoral: React.FC = () => {
  const { id } = useParams();
  const location = useLocation();
  const [coralData, setCoralData] = useState<ClientCoral | null>(null);
  
  useEffect(() => {
    if (id) {
      const referralUrl = `${window.location.origin}${location.pathname}`;
      storeReferralUrl(referralUrl);
    }
  }, [id, location.pathname]);

  useEffect(() => {
    if (!coralData) {
      return;
    }  
    trackEvent(EventName.PUBLIC_SHARED_CORAL_VIEW, { 
      coralId: id || '', 
      coralName: coralData.coralName
    });
  }, [coralData, id]);

  const scrollToTop = useCallback(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }, []);

  if (!id) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-xl text-gray-600">No coral ID provided</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col mx-auto overflow-hidden">
      <MetaHead 
        title={`Back ${coralData?.coralName || 'Coral'} | on Coral`}
        description="Back the music that moves you. Join a music ecosystem."
      />
      {/* Hero Section */}
      <section className="relative w-full flex items-center justify-center min-h-[70vh] sm:min-h-[75vh] overflow-hidden">
        <video
          autoPlay
          muted
          loop
          playsInline
          className="absolute inset-0 w-full h-full object-cover"
          onError={(e) => {
            console.error("Video loading error:", e);
          }}
        >
          <source src="https://wearecoral-public-data-prod.s3.ap-southeast-2.amazonaws.com/HipHopConcert.mov" type="video/mp4" />
        </video>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-[#0B0D0D] opacity-90" />
        <div className="absolute inset-0 bg-black bg-opacity-30" />
        <div className="relative w-full max-w-screen-2xl mx-auto px-3 pt-24 pb-0 md:pb-24 flex flex-col md:flex-row items-center justify-center">
          <div className="md:w-5/12 px-5 text-center md:text-left pb-12 sm:pb-0">
             <div className="flex w-full">
              <div className="text-left">
                <h1 className="font-syne font-medium text-4xl sm:text-5xl md:text-6xl font-extrabold mb-4 leading-tight">
                  {coralData && (
                    <div className="font-syne text-white text-3xl font-semibold tracking-wider mb-0">
                      BACK
                    </div>
                  )}
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                    {coralData?.coralName || 'Loading...'}
                  </span>
                </h1>
              </div>
            </div>
            <p className="text-white text-xl sm:text-2xl font-syne py-2 mb-6">
              Enjoy vibrant music scenes by backing the music you value. Your contributions encourage artists, and keeps music (not money) at the heart of their work.
            </p>
            <p className="text-white text-xl sm:text-2xl font-nunito">
              Add your coral to the ecosystem to:
            </p>
            <ul className="text-white text-xl sm:text-2xl font-nunito space-y-2 py-2">
              <li>• Send micro-signals of appreciation for these artists</li>
              <li>• Feel good backing the music you value</li>
              <li>• Enjoy more music and thriving scenes</li>
            </ul>
            <div className="text-sm text-gray-300 mt-4">
              <span 
                className="cursor-help" 
                data-tooltip-id="compareTooltip" 
                data-tooltip-html="wearecoral: 7.75% fee<br />Bandcamp: 10%-15% fee<br />Patreon: 8%-12% fee<br />Merchandise: 40-60% to artist<br />Streaming: <1% to artist per play"
              >
                + more of your monthly contribution reaches artists than on <span className="underline">other platforms</span>
              </span>
              <Tooltip id="compareTooltip" className="z-50" />
            </div>
          </div>
          <div className="md:w-1/3 bg-black bg-opacity-20 backdrop-blur-md rounded-lg shadow-xl p-2">
            <AllInOneCoralCreator 
              sharedCoralId={id} 
              transparent={true}
              onCoralLoaded={setCoralData}
            />
          </div>
        </div>
      </section>

    {/* Key Details Section */}
    <section className="bg-gradient-to-b from-[#080909] to-coral-deep-blue pb-0 pt-8 sm:py-8 text-white">
      <div className="max-w-screen-xl mx-auto px-8 lg:px-16">                   
        <h3 className="text-xl md:text-2xl mb-4 flex justify-center text-center">
          <span className="bg-clip-text font-extrabold text-transparent bg-gradient-to-r from-coral-pink to-coral-grad-blue-light">
            HOW IT WORKS
          </span>
        </h3>
    
        <h2 className="font-syne text-xl sm:text-2xl md:text-3xl max-w-3xl mx-auto mb-6 text-center">
          Personalise your Coral to back <span className="text-coral-pink" data-tooltip-id='any-artists' data-tooltip-html="Nominate artists, even if they're not on our platform.<br />We'll notify them of waiting funds.<br /><br />This community-driven approach reflects recognition of <br />the value we've already received from their music, and<br />is how we can step forward to value them.<br /><br />Artists have 9 months from the first notification to<br />sign up before funds are redistributed to other<br />artists you nominate, or refunded.">any artists</span> you value with regular micro-signals of appreciation for their art.
        </h2>
        
        <p className="font-syne text-xl sm:text-2xl md:text-3xl max-w-3xl mx-auto mb-6 text-center">
          Your monthly contribution goes directly to your chosen artists, so they can make more of the music you love.
        </p>
    
        <p className="font-syne text-transparent bg-clip-text bg-gradient-to-r from-coral-pink to-coral-orange text-xl sm:text-2xl md:text-3xl max-w-3xl mx-auto mb-12 text-center">
          Enjoy vibrant, diverse music scenes you help sustain.
        </p>
        
        <Tooltip id="any-artists" className="z-50" />
      </div>
    </section>

    <section 
        className="relative bg-coral-deep-blue bg-no-repeat bg-contain bg-bottom lg:bg-cover text-white flex flex-col items-start min-h-[70vh] sm:min-h-screen"
        style={{ 
            backgroundImage: `url(${mainBackgroundImage})`,
        }}
    >
        <div className="w-full mx-auto mt-12">
            <RecentCoralVisualisation />
        </div>
    </section>

      {/* Ecosystem Section */}
      <section className="bg-[#0C142D] text-white">
        <div className="p-8 md:p-16">
          <div className="max-w-screen-xl mx-auto">
            <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between">
              <div className="lg:w-1/2 mb-8 lg:mb-0">
                <h2 className="text-xl md:text-2xl font-extrabold mb-2 sm:mb-4">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                    OUR VISION
                  </span>
                </h2>
                <h3 className="font-syne text-2xl md:text-4xl mb-0 sm:mb-8 leading-tight mr-0 sm:mr-12">
                  A thriving ecosystem where music is valued and diverse styles and scenes can prosper.
                </h3>
              </div>
              <div className="lg:w-1/2 mb-6 sm:mb-14">
                <CoralEcosystem />
              </div>
            </div>
          </div>
        </div>
        <img src={blueWaveImage} alt="Blue Wave" className="w-full" />
      </section>

      {/* How It Works Section */}
      <section className="bg-coral-deep-blue text-white">
        <div className="max-w-screen-xl mx-auto px-8 sm:px-6 lg:px-8">
          <h2 className="text-xl md:text-2xl font-extrabold mb-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
              CORAL DYNAMICS
            </span>
          </h2>
          <CollectiveImpactCalculator />
          <div className="flex justify-center mt-16">
            <CoralButton 
              onClick={scrollToTop} 
              className="text-lg px-8"
              popOnHover
            >
              Build Your Coral
            </CoralButton>
          </div>
        </div>
        <div className="relative mt-16">
          <img src={darkBlueWaveImage} alt="Dark Blue Wave" className="w-full" />
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-dark-blue to-transparent"></div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="bg-coral-dark-blue text-white p-8 md:p-16">
        <div className="max-w-screen-xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            <div>
              <h2 className="text-xl md:text-2xl font-extrabold mb-4">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                  SUPPORT
                </span>
              </h2>
              <h3 className="font-syne text-3xl md:text-4xl mb-6 leading-tight">
                FAQs
              </h3>
              <p className="text-xl mb-8">
                Everything you need to know about backing artists through wearecoral.
              </p>
            </div>
            <div className="space-y-4">
              {faqData.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          </div>
          <div className="flex justify-center mt-16">
            <CoralButton 
              onClick={scrollToTop}
              className="text-lg px-8"
              popOnHover
            >
              Start Your Coral
            </CoralButton>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PublicCoral;