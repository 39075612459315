import { useState, useEffect, useCallback } from 'react';

export interface GenreData {
  name: string;
  amount: number;
}

export const useEcosystemData = () => {
  const [data, setData] = useState<GenreData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const stage = 'prod' // process.env.REACT_APP_ENVIRONMENT || 'dev';
      const url = `https://wearecoral-public-data-${stage}.s3.ap-southeast-2.amazonaws.com/wearecoral-contribution-report.csv`;
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch data');
      
      const text = await response.text();
      const rows = text.split('\n').slice(1);
      const genreMap = new Map<string, number>();

      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      rows.forEach(row => {
        const [dateStr, , amount, , , category] = row.split(',');
        const rowDate = new Date(dateStr.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));
        
        if (rowDate >= thirtyDaysAgo && category && (category !== 'Fees' && category !== 'Unknown')) {
          const currentAmount = genreMap.get(category) || 0;
          genreMap.set(category, currentAmount + Number(amount));
        }
      });

      setData(Array.from(genreMap, ([name, amount]) => ({ name, amount })));
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching ecosystem data:', err);
      setError('Failed to load ecosystem data');
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, isLoading, error };
};
