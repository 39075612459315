import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  ArtistSearchResult,
  ClientArtistListItem,
  ClientCoral,
  Source,
  SignalArtistAppreciation,
} from 'shared/types/platformTypes';
import useCoralEditor from '../../hooks/useCoralEditor';
import { useAuthContext } from '../Auth/AuthContext/AuthContext';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import ArtistSearch from '../../components/ArtistSearch/ArtistSearch';
import CoralButton from '../../components/CoralButtons/CoralButton';
import Spinner from '../../components/Spinner/Spinner';
import { Tooltip } from 'react-tooltip';
import {
  ChevronDown,
  ChevronUp,
  Edit2,
  ArrowUp
} from 'lucide-react';
import selectedStar from '../../assets/images/selected-star.png';
import unSelectedStar from '../../assets/images/unselected-star.png';
import disabledIcon from '../../assets/images/disabled-icon.png';
import plusIcon from '../../assets/images/create-coral/add-artist.png';
import addedIcon from '../../assets/images/create-coral/added-artist.png';
import CoralVisualisation from '../../components/CoralVisualisation/CoralVisualisation';
import { useNavigate } from 'react-router-dom';

// Remove local interface since we're using the shared one

interface AppreciationSignalState {
  artistMessages: SignalArtistAppreciation[];
}

interface AllInOneCoralCreatorProps {
  artistId?: string;
  sharedCoralId?: string;
  transparent?: boolean;
  onCoralLoaded?: (coral: ClientCoral) => void;
}

const AllInOneCoralCreator: React.FC<AllInOneCoralCreatorProps> = ({
  artistId,
  sharedCoralId,
  transparent = false,
  onCoralLoaded,
}) => {
  const { userProfile } = useAuthContext();
  const {
    coralData,
    addArtistDirectly,
    removeArtist,
    updatePledgeAmount,
    saveCoral,
    simulateCoral,
    simulationResults,
    addPinnedArtist,
    removePinnedArtist,
    updateAppreciationSignals,
  } = useCoralEditor(
    sharedCoralId || '',
    !!sharedCoralId,
    sharedCoralId ? undefined : artistId,
    true // Always create shared corals as user corals in this component
  );

  const [pledgeValue, setPledgeValue] = useState('');
  const [isEditingPledge, setIsEditingPledge] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [isAddingArtist, setIsAddingArtist] = useState(false);
  const [isFeesExpanded, setIsFeesExpanded] = useState(false);

  const [appreciationSignalState, setAppreciationSignalState] = useState<AppreciationSignalState>({
    artistMessages: [],
  });

  const navigate = useNavigate();

  const toggleFees = () => {
    setIsFeesExpanded(!isFeesExpanded);
    trackEvent(
      isFeesExpanded
        ? EventName.CORAL_FEES_COLLAPSED
        : EventName.CORAL_FEES_EXPANDED,
      { userId: userProfile?.userId }
    );
  };

  const selectedArtists =
    coralData?.artistPool?.artistList.filter(
      (artist) => artist.selected || artist.source === Source.USER
    ) || [];

  useEffect(() => {
    if (coralData) {
      setPledgeValue(coralData.pledgeAmount.toString());

      // Ensure we simulate when loading an existing coral
      if (
        coralData.pledgeAmount > 0 &&
        coralData.artistPool?.artistList.length > 0
      ) {
        simulateCoral();
      }
    }
  }, [coralData, simulateCoral]);

  const handleUpdatePledgeAmount = useCallback(async () => {
    const decimalRegex = /^([1-9]\d*(\.\d{1,2})?)?$/;

    if (decimalRegex.test(pledgeValue)) {
      const amount = Number(pledgeValue);
      if (amount < 1 || amount > 70) {
        setValidationMessage('Amount must be between $1 and $70');
      } else {
        setValidationMessage('');
        setIsEditingPledge(false);

        const oldAmount = coralData?.pledgeAmount || 0;
        await updatePledgeAmount(amount);

        trackEvent(EventName.CORAL_PLEDGE_AMOUNT_CHANGED, {
          userId: userProfile?.userId,
          oldAmount,
          newAmount: amount,
        });
      }
    } else {
      setValidationMessage('Invalid pledge amount');
    }
  }, [pledgeValue, updatePledgeAmount, userProfile, coralData]);

  const [isResponsiveLayout, setIsResponsiveLayout] = useState(false);

  const shouldUseResponsiveLayout = useCallback(() => {
    if (selectedArtists.length >= 3) return true;
    return selectedArtists.some(
      (artist) => artist.artistName && artist.artistName.length > 11
    );
  }, [selectedArtists]);

  useEffect(() => {
    setIsResponsiveLayout(shouldUseResponsiveLayout());
  }, [shouldUseResponsiveLayout]);

  const handleAddArtist = useCallback(
    async (artistName: string, artistGuid?: string) => {
      if (artistName.trim()) {
        try {
          await addArtistDirectly(artistName.trim(), artistGuid);
          setIsAddingArtist(false);
          trackEvent(EventName.CORAL_ARTIST_ADDED, {
            userId: userProfile?.userId,
            artistName: artistName.trim(),
            artistId: artistGuid,
            totalArtists: selectedArtists.length + 1,
          });
        } catch (error) {
          console.error('Error adding artist:', error);
        }
      }
    },
    [addArtistDirectly, userProfile, selectedArtists.length]
  );

  const handleSelectArtist = useCallback(
    (artist: ArtistSearchResult) => {
      handleAddArtist(artist.name, artist.guid);
    },
    [handleAddArtist]
  );

  const handleAddToEcosystem = useCallback(async () => {
    try {
      const savedCoralGuid = await saveCoral();
      trackEvent(EventName.CORAL_GOTO_PAYMENT, {
        userId: userProfile?.userId,
        coralId: savedCoralGuid!,
        pledgeAmount: coralData!.pledgeAmount,
        totalArtists: selectedArtists.length,
      });
      navigate(`/payment/${savedCoralGuid}?amt=${coralData?.pledgeAmount}`);
    } catch (error) {
      console.error('Error saving coral:', error);
    }
  }, [saveCoral, userProfile, coralData, selectedArtists.length, navigate]);

  const totalFees = useMemo(() => {
    return simulationResults.platformFee + simulationResults.transactionFee;
  }, [simulationResults.platformFee, simulationResults.transactionFee]);

  const renderArtistStatus = useCallback((artist: ClientArtistListItem) => {
    if (artist.artistListItemStatus === 'pending') {
      return (
        <button
          data-tooltip-id="searchingTooltip"
          data-tooltip-content="We're searching for this artist in our database. Try a different spelling if nothing returns."
        >
          <Spinner className="w-5 h-5 animate-pulse" />
          <Tooltip id="searchingTooltip" />
        </button>
      );
    } else if (artist.artistListItemStatus === 'not found') {
      return <img src={plusIcon} alt="Add artist" className="w-5 h-5" />;
    } else if (artist.artistListItemActive === false) {
      return (
        <button
          data-tooltip-id="disabledIconTooltip"
          data-tooltip-content={
            artist.artistListItemStatus === 'top-artist'
              ? 'This artist has reached high levels of commercial success and is not eligible for wearecoral.'
              : artist.artistListItemStatus === 'deceased'
              ? "wearecoral is for living artists as it's about supporting a thriving ecosystem."
              : 'This artist is not eligible for wearecoral.'
          }
        >
          <img src={disabledIcon} alt="Artist is disabled" className="w-4 h-4" />
          <Tooltip id="disabledIconTooltip" />
        </button>
      );
    } else {
      return <img src={addedIcon} alt="Artist added" className="w-5 h-5" />;
    }
  }, []);

  const handleRemoveArtist = useCallback(
    (artistName: string) => {
      if (window.confirm('Are you sure you want to remove this artist?')) {
        removeArtist(artistName);
        trackEvent(EventName.CORAL_ARTIST_REMOVED, {
          userId: userProfile?.userId,
          artistName,
          totalArtists: selectedArtists.length - 1,
        });
      }
    },
    [removeArtist, userProfile, selectedArtists.length]
  );

  const handleTogglePinArtist = useCallback(
    (artistId: string) => {
      const artist = selectedArtists.find((a) => a.artistId === artistId);
      if (coralData && coralData?.pinnedArtists.includes(artistId)) {
        removePinnedArtist(artistId);
        if (artist) {
          trackEvent(EventName.CORAL_ARTIST_UNPINNED, {
            userId: userProfile?.userId,
            artistName: artist.artistName!,
            artistId,
          });
        }
      } else if (
        coralData &&
        coralData.pinnedArtists &&
        coralData?.pinnedArtists.length < 2
      ) {
        addPinnedArtist(artistId);
        if (artist) {
          trackEvent(EventName.CORAL_ARTIST_PINNED, {
            userId: userProfile?.userId,
            artistName: artist.artistName!,
            artistId,
          });
        }
      }
    },
    [coralData, addPinnedArtist, removePinnedArtist, userProfile, selectedArtists]
  );

  // Prepare data for CoralVisualisation
  const coralVisualisationData = useMemo(() => {
    if (
      !coralData ||
      !simulationResults ||
      coralData.pledgeAmount === 0 ||
      (coralData.artistPool.artistList ?? []).length === 0
    )
      return null;

    const genreData = coralData.artistPool?.artistList.reduce(
      (acc, artist) => {
        const genre = artist.artistData?.primaryGenre;
        if (genre) {
          const existingGenre = acc.find((g) => g.name === genre);
          if (existingGenre) {
            existingGenre.amount +=
              simulationResults.artistAllocations.find(
                (a) => a.artist_id === artist.artistId
              )?.allocated_amount || 0;
          } else {
            acc.push({
              name: genre,
              amount:
                simulationResults.artistAllocations.find(
                  (a) => a.artist_id === artist.artistId
                )?.allocated_amount || 0,
            });
          }
        }
        return acc;
      },
      [] as { name: string; amount: number }[]
    );

    if (!genreData || genreData.length === 0) return null;

    return {
      transactionId: coralData.guid,
      totalAmount: coralData.pledgeAmount,
      genres: genreData || [],
      artists: simulationResults.artistAllocations
        .filter((a) => a.allocated_amount > 0)
        .map((a) => ({
          name: a.artist_name,
          amount: a.allocated_amount,
        })),
    };
  }, [coralData, simulationResults]);

  // Determine container styling
  const containerClassName = transparent
    ? 'max-w-2xl min-w-xl mx-auto bg-white/10 backdrop-blur-md p-4 py-6 rounded-lg text-white'
    : 'max-w-2xl min-w-xl mx-auto p-6 bg-white rounded-lg shadow-lg text-black';

  const getTextColor = (defaultColor: string, transparentColor: string) => {
    return transparent ? transparentColor : defaultColor;
  };

  // Call onCoralLoaded when coral data is available
  useEffect(() => {
    if (coralData && onCoralLoaded) {
      onCoralLoaded(coralData);
    }
  }, [coralData, onCoralLoaded]);

  // Update getArtistMessage to handle required artistId
  const getArtistMessage = (artistId: string): string => {
    return appreciationSignalState.artistMessages.find(m => m.artistId === artistId)?.message || '';
  };

  // Update handleUpdateAppreciationSignal to require artistId
  const handleUpdateAppreciationSignal = (message: string, artistId: string) => {
    setAppreciationSignalState(prev => {
      const existing = prev.artistMessages.findIndex(m => m.artistId === artistId);
      const artist = selectedArtists.find(a => a.artistId === artistId);

      if (!artist) return prev;

      let newState: AppreciationSignalState;
      if (existing >= 0) {
        const newMessages = [...prev.artistMessages];
        newMessages[existing] = {
          artistId,
          artistName: artist.artistName!,
          message,
        };
        newState = { ...prev, artistMessages: newMessages };
      } else {
        newState = {
          artistMessages: [
            ...prev.artistMessages,
            {
              artistId,
              artistName: artist.artistName!,
              message,
            },
          ],
        };
      }

      // Update the coral with the new appreciation signals
      updateAppreciationSignals(newState.artistMessages);
      return newState;
    });
  };

  // Add new helper for auto-resize textarea
  const handleTextareaResize = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = e.target;
    textarea.style.height = 'auto'; // Reset height
    textarea.style.height = `${Math.min(textarea.scrollHeight, 300)}px`; // Set new height up to max
  };

  const renderArtistRow = (artist: ClientArtistListItem, index: number) => {
    const message = getArtistMessage(artist.artistId!);
    const allocatedAmount = simulationResults.artistAllocations.find(
      (a) => a.artist_id === artist.artistId
    )?.allocated_amount;

    return (
      <div key={index} className="mb-6">
        <div
          className={`flex items-start justify-between py-2 border-b ${getTextColor(
            'border-gray-200',
            'border-gray-600'
          )} cursor-pointer hover:bg-gray-50/20 dark:hover:bg-gray-800/20`}
          onClick={(e) => {
            if (!(e.target as HTMLElement).closest('.pin-star')) {
              handleRemoveArtist(artist.artistName!);
            }
          }}
        >
          <div className="flex items-start flex-grow mr-2 min-w-0">
            <img
              src={
                coralData?.pinnedArtists.includes(artist.artistId!)
                  ? selectedStar
                  : unSelectedStar
              }
              alt="Pin artist"
              className="w-6 h-6 cursor-pointer mr-4 flex-shrink-0 pin-star"
              onClick={(e) => {
                e.stopPropagation();
                handleTogglePinArtist(artist.artistId!);
              }}
            />
            <span
              className={`font-medium break-words ${getTextColor(
                'text-gray-900',
                'text-white'
              )}`}
            >
              {artist.artistName}
            </span>
          </div>
          <div className="flex items-center flex-shrink-0 w-24 justify-end">
            <span
              className={`mr-2 text-sm whitespace-nowrap ${getTextColor(
                'text-gray-600',
                'text-gray-300'
              )}`}
            >
              {coralData && coralData.pledgeAmount > 0
                ? `$${(allocatedAmount || 0).toFixed(2)}`
                : ''}
            </span>
            <div className="w-5 h-5 flex-shrink-0">{renderArtistStatus(artist)}</div>
          </div>
        </div>

        {/* Always show appreciation textarea */}
        <div className="mt-2 pl-10">
          <textarea
            placeholder={`Share a brief expression of appreciation for ${artist.artistName}'s work`}
            value={message}
            onChange={(e) => {
              handleUpdateAppreciationSignal(e.target.value, artist.artistId!);
              handleTextareaResize(e);
            }}
            className={`w-full p-3 rounded-lg resize-none transition-all duration-200 min-h-[80px] ${
              transparent
                ? 'bg-white/10 backdrop-blur-sm border border-gray-500 text-white placeholder-gray-400 focus:border-coral-pink'
                : 'bg-gray-50 border border-gray-200 text-gray-900 placeholder-gray-500 focus:border-coral-orange'
            } focus:ring-1 focus:ring-coral-pink`}
            style={{ overflow: 'hidden' }}
            rows={2}
            maxLength={300}
          />
          <div className={`text-xs mt-1 ${getTextColor('text-gray-400', 'text-gray-300')}`}>
            {message.length}/300
          </div>
        </div>
      </div>
    );
  };

  // ----
  // PROGRESS INDICATOR LOGIC
  // ----
  const steps = 2;
  let complete = 0;
  if (selectedArtists.length > 0) complete++;
  if (coralData?.pledgeAmount && coralData.pledgeAmount > 0) complete++;
  const progress = (complete / steps) * 100;

  // Modify the helper for step text
  const getStepText = () => {
    if (selectedArtists.length === 0) {
      return "1 of 2 steps (Add an artist)";
    }
    if (!coralData?.pledgeAmount || coralData.pledgeAmount === 0) {
      return "2 of 2 steps (Set monthly amount)";
    }
    return "Ready to join the ecosystem!";
  };

  return (
    <div className={containerClassName}>
      {/* Progress Indicator */}
      <div className="mb-6">
        <div className="font-nunito text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
          Personalise Your Coral
        </div>
        <p
          className={`font-nunito text-base ${getTextColor(
            'text-gray-400',
            'text-gray-300'
          )}`}
        >
          A coral is the collection of artists you back monthly
        </p>
        <div className="mt-3 w-full bg-gray-200 rounded-full h-2 relative">
          <div
            className="bg-coral-orange h-2 rounded-full absolute top-0 left-0 transition-all"
            style={{ width: `${progress}%` }}
          />
        </div>
        <p className="text-sm text-gray-500 mt-1 text-right">
          {getStepText()}
        </p>
      </div>

      <div className={`flex flex-col ${!isResponsiveLayout && coralVisualisationData ? 'lg:flex-row' : ''}`}>
        {/* Left Section: Artists in Your Coral */}
        <div
          className={`w-full ${
            !isResponsiveLayout && coralVisualisationData ? 'lg:w-1/2 lg:pr-4' : ''
          } mb-8`}
        >
          <h4
            className={`font-nunito text-sm font-bold uppercase ${getTextColor(
              'text-gray-400',
              'text-gray-300'
            )}`}
          >
            Artists
          </h4>
          <div className="mb-4">
            {!coralData ? (
              // Loading skeleton
              <div className="animate-pulse flex items-start justify-between py-2 border-b border-gray-200">
                <div className="flex items-start">
                  <div className="w-6 h-6 rounded-full bg-gray-200 mr-4" />
                  <div className="w-32 h-5 bg-gray-200 rounded" />
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-16 h-5 bg-gray-200 rounded" />
                  <div className="w-5 h-5 bg-gray-200 rounded-full" />
                </div>
              </div>
            ) : (
              selectedArtists.map((artist, index) => renderArtistRow(artist, index))
            )}
          </div>
          {isAddingArtist ? (
            <ArtistSearch
              onSelect={handleSelectArtist}
              onSubmit={handleAddArtist}
              placeholder="Enter artist name"
            />
          ) : (
            <button
    type="button"
    className="
      inline-block
      px-3
      py-1
      text-sm
      font-medium
      text-coral-orange
      border
      border-coral-orange
      rounded-md
      hover:bg-coral-orange
      hover:text-white
      transition-colors
      duration-300
    "
    onClick={() => setIsAddingArtist(true)}
  >
    + Add more artists
  </button>
          )}
        </div>

        {/* Right Section: Visualize Your Coral */}
        {!isResponsiveLayout && coralVisualisationData && (
          <div className="w-full lg:w-1/2 lg:pl-4">
            <div className="flex justify-center items-center w-full pt-5">
              <CoralVisualisation coral={coralVisualisationData} chartOnly={true} size={180} />
            </div>
          </div>
        )}
      </div>

      {/* Visualization for responsive layout */}
      {isResponsiveLayout && coralVisualisationData && (
        <div className="w-full mb-8">
          <div className="flex justify-center items-center w-full">
            <CoralVisualisation coral={coralVisualisationData} chartOnly={true} size={180} />
          </div>
        </div>
      )}

      {/* Bottom Section: Fees & CTA */}
      <div className={`pt-8 border-t ${getTextColor('border-gray-200', 'border-gray-600')}`}>
        {/* Top Section: Monthly Contribution */}
        <div className="mb-1">
          <div className="flex items-center justify-between">
            <h2
              className={`font-nunito text-base font-bold ${getTextColor(
                'text-gray-900',
                'text-white'
              )}`}
            >
              Monthly Contribution{' '}
              <span className={getTextColor('text-gray-600', 'text-gray-300')}>
                (AUD)
              </span>
            </h2>
            <div className="flex items-center ml-4">
              <span className={getTextColor('text-gray-600', 'text-gray-300')}>$</span>
              {isEditingPledge ? (
                <input
                  type="text"
                  inputMode="numeric"
                  value={pledgeValue}
                  onChange={(e) => setPledgeValue(e.target.value)}
                  onBlur={handleUpdatePledgeAmount}
                  onKeyPress={(e) => e.key === 'Enter' && handleUpdatePledgeAmount()}
                  className={`w-16 p-1 border rounded-md focus:ring-blue-500 focus:border-blue-500 ${
                    transparent
                      ? 'bg-white bg-opacity-20 text-white border-gray-500'
                      : 'border-gray-300'
                  }`}
                  autoFocus
                  placeholder=""
                />
              ) : (
                <div
                  className={`flex items-center cursor-pointer rounded px-2 py-1 transition-colors duration-200 ${
                    coralData?.pledgeAmount === 0
                      ? 'text-coral-pink'
                      : getTextColor('text-gray-600', 'text-white')
                  }`}
                  onClick={() => {
                    if (coralData?.pledgeAmount === 0) {
                      setPledgeValue('');
                    } else {
                      setPledgeValue(coralData?.pledgeAmount.toFixed(2) || '');
                    }
                    setIsEditingPledge(true);
                  }}
                >
                  <span className="text-base mr-2">
                    {coralData?.pledgeAmount === 0 ? (
                      <i className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange text-sm hidden sm:block">
                        Set amount
                      </i>
                    ) : (
                      coralData?.pledgeAmount.toFixed(2)
                    )}
                  </span>
                  <Edit2
                    className={
                      coralData?.pledgeAmount === 0
                        ? 'text-coral-pink animate-pulse'
                        : ''
                    }
                  />
                </div>
              )}
            </div>
          </div>
          {coralData?.pledgeAmount === 0 && (
            <p
              className={`font-nunito mt-2 text-center text-sm ${getTextColor(
                'text-gray-300',
                'text-gray-300'
              )}`}
            >
              Consider contributing the price of a single drink at a local gig
            </p>
          )}
          {validationMessage && (
            <p className="text-red-500 mt-2 text-sm">{validationMessage}</p>
          )}
        </div>

        {/* Fees Section */}
        {coralData && coralData.pledgeAmount > 0 && (
          <div className="mb-6">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={toggleFees}
            >
              <h3
                className={`text-sm font-medium ${getTextColor(
                  'text-gray-400',
                  'text-gray-300'
                )}`}
              >
                Fees
              </h3>
              <div className="flex items-center">
                <span
                  className={`text-sm mr-4 ${getTextColor(
                    'text-gray-600',
                    'text-gray-300'
                  )}`}
                >
                  ${totalFees.toFixed(2)}
                </span>
                {isFeesExpanded ? (
                  <ChevronUp
                    size={20}
                    className={getTextColor('text-gray-600', 'text-gray-300')}
                  />
                ) : (
                  <ChevronDown
                    size={20}
                    className={getTextColor('text-gray-600', 'text-gray-300')}
                  />
                )}
              </div>
            </div>
            {isFeesExpanded && (
              <div
                className={`mt-2 space-y-2 ${getTextColor(
                  'text-gray-600',
                  'text-gray-300'
                )}`}
              >
                <div className="flex items-center justify-between text-sm">
                  <span>Platform fee (incl GST)</span>
                  <span>${simulationResults.platformFee.toFixed(2)}</span>
                </div>
                <div className="flex items-center justify-between text-sm">
                  <span>Payment processing fee</span>
                  <span>${simulationResults.transactionFee.toFixed(2)}</span>
                </div>
              </div>
            )}
          </div>
        )}

        {/* CTA */}
        <div className="mt-4">
          <CoralButton
            onClick={handleAddToEcosystem}
            className={`w-full px-6 py-3 text-lg font-semibold relative overflow-hidden ${
              selectedArtists.length === 0 || coralData?.pledgeAmount === 0
                ? 'opacity-90 cursor-not-allowed'
                : ''
            }`}
            disabled={selectedArtists.length === 0 || coralData?.pledgeAmount === 0}
          >
            {selectedArtists.length === 0 ? (
              <span className="flex items-center justify-center">
                <span className="mr-2 bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                  Add an artist to start
                </span>
                <ArrowUp className="w-4 h-4 animate-bounce text-coral-orange" />
              </span>
            ) : coralData?.pledgeAmount === 0 ? (
              <span className="flex items-center justify-center">
                <span className="mr-2 bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                  Set an amount to continue
                </span>
                <ArrowUp className="w-4 h-4 animate-bounce text-coral-orange" />
              </span>
            ) : (
              'Add Your Coral to the Ecosystem'
            )}
          </CoralButton>
        </div>
      </div>
    </div>
  );
};

export default AllInOneCoralCreator;