import React, { useEffect, useRef } from 'react';
import mainBackgroundImage from '../../../assets/images/coral-reef-black.svg';
import blueWaveImage from '../../../assets/images/blue-wave.png';
import darkBlueWaveImage from '../../../assets/images/dark-blue-wave.png';
import FAQItem from '../../../components/FAQItem/faqItem';
import { faqData } from './faqForArtists';
import CollectiveImpactTable from '../../../components/CollectiveImpactTable/CollectiveImpactTable';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import RecentCoralVisualisation from '../../../components/RecentCoralVisualisation/RecentCoralVisualisation';
import CoralEcosystem from '../../../components/CoralEcosystem/CoralEcosystem';
import ArtistPayoutChecker from '../../../components/ArtistPayoutChecker/ArtistPayoutChecker';
import ArtistProfileTools from '../../../components/ArtistProfileTools/ArtistProfileTools';
import CoralButton from '../../../components/CoralButtons/CoralButton';

const ForArtists = () => {
    const artistProfileToolsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        trackEvent(EventName.FOR_ARTIST_PAGE_VIEW, {});
    }, []);

    const scrollToArtistProfileTools = () => {
        console.log("Scroll function called");
        if (artistProfileToolsRef.current) {
            console.log("Ref exists, scrolling", artistProfileToolsRef.current);
            setTimeout(() => {
                artistProfileToolsRef.current!.scrollIntoView({ 
                    behavior: 'smooth', 
                    block: 'start' 
                });
            }, 100); // Delay to ensure DOM is fully rendered
        } else {
            console.log("Ref does not exist");
        }
    };

    return (
        <div className="flex flex-col min-h-screen mx-auto overflow-hidden">
        {/* Hero Section with Video Background */}
        <section className="relative w-full flex items-center justify-center min-h-[70vh] sm:min-h-[75vh] overflow-hidden">
            <video
                autoPlay
                muted
                loop
                playsInline
                className="absolute inset-0 w-full h-full object-cover"
                onError={(e) => {
                    console.error("Video loading error:", e);
                }}
            >
            <source src={`https://wearecoral-public-data-prod.s3.ap-southeast-2.amazonaws.com/HipHopConcert.mov`} type="video/mp4" />
            </video>
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-[#0B0D0D] opacity-90" />
            <div className="absolute inset-0 bg-black bg-opacity-30" />
            <div ref={artistProfileToolsRef} className="relative w-full max-w-screen-2xl mx-auto px-3 pt-24 md:pt-60 pb-0 md:pb-24 flex flex-col md:flex-row items-center justify-center">
                <div className="md:w-5/12 px-0 sm:px-4 md:px-10 text-center md:text-left">
                    <h1 className="font-syne text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-white font-medium mb-4 sm:mb-7 leading-tight">
                        Your music moves people.<br/>Let them show it.
                    </h1>
                    <p className="font-syne font-semibold text-xl sm:text-2xl md:text-3xl text-gray-200 mb-8 sm:mb-12">
                        Share your artist page, and let your audience express what your music means to them.
                    </p>
                </div>
                <div className="md:w-6/12 hidden md:block">
                    <ArtistProfileTools />
                </div>
            </div>
        </section>

        {/* Key Advantages Section */}
        <section className="bg-gradient-to-b from-[#080909] to-coral-deep-blue sm:pt-24 pb-8 text-white">
            <div className="max-w-screen-xl mx-auto px-4 block md:hidden">
                <ArtistProfileTools />
            </div>
            <div className="max-w-screen-xl mx-auto px-8 lg:px-16 pt-16 sm:pt-0">
                <h2 className="text-xl md:text-2xl font-extrabold mb-8">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                        KEY ADVANTAGES
                    </span>
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div>
                        <h3 className="font-syne text-2xl mb-4">Beyond Simple</h3>
                        <p>Enter your artist name - that&apos;s it. No setup, content strategy or tiers required. Your audience can back you before you even create a page.</p>
                    </div>
                    <div>
                        <h3 className="font-syne text-2xl mb-4">Focus on Music Creation</h3>
                        <p>No content obligations. No rewards needed. Your fans already know how your music impacts their lives - they just want simple ways to back your creative journey. </p>
                    </div>
                    <div>
                        <h3 className="font-syne text-2xl mb-4">Sustainable Backing</h3>
                        <p>Regular income to pursue your craft with confidence. Higher percentages than Patreon & Bandcamp, more reliable than merch sales.</p>
                    </div>
                </div>
            </div>
        </section>          

            {/* Our Strategy Section */}
            <section className="bg-coral-deep-blue py-8 text-white">
                <div className="max-w-screen-lg mx-auto sm:text-center px-8 lg:px-16">
                <h2 className="text-xl md:text-2xl font-extrabold mb-4">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                        OUR STRATEGY
                    </span>
                </h2>
                <h3 className="font-syne text-2xl md:text-3xl">
                    Make backing music simple and collective.
                </h3>
                </div>
            </section>
            <section 
                className="relative bg-coral-deep-blue bg-no-repeat bg-contain bg-bottom 3xl:bg-cover pb-6 sm:pb-16 md:pb-24 text-white"
                style={{ 
                    backgroundImage: `url(${mainBackgroundImage})`,
                }}
            >
                <div className="max-w-screen-lg mx-auto sm:text-center px-6 lg:px-8 text-lg">                    
                    <p className="mb-8">
                        Your community wants a better way to be part of your musical journey because existing ways fall short. Gigs and merch are great, but dependent on time and place. Vinyl caters to a niche audience, and platforms like Bandcamp fragment listeners&apos; music libraries. Patreon demands singular support, and that you create content beyond your music.
                    </p>

                    <p className="mb-12">
                        wearecoral enables a simple social contract: communities make space for artists to focus on their art.
                    </p>
                    
                    <h3 className="text-xl md:text-2xl mb-4">
                        <span className="bg-clip-text font-extrabold text-transparent bg-gradient-to-r from-coral-pink to-coral-grad-blue-light">
                            HERE&apos;S HOW
                        </span>
                    </h3>
                    
                    <p className="font-syne text-xl md:text-2xl max-w-3xl mx-auto mb-12">
                        Collective Micro-Patronage: Fans create their own &ldquo;coral&ldquo; - selecting you and other artists they want to invest in. Their monthly contribution is split directly between their chosen artists. Simple, transparent, and direct.
                    </p>
                    
                    <div className="mb-12">
                        <RecentCoralVisualisation />
                    </div>

                    <h3 className="text-xl md:text-2xl mb-4">
                        <span className="bg-clip-text font-extrabold text-transparent bg-gradient-to-r from-coral-pink to-coral-grad-blue-light">
                            HERE&apos;S WHY
                        </span>
                    </h3>
                    
                    <p className="font-syne text-xl md:text-2xl max-w-3xl mx-auto mb-4">
                        Patronage Works: A sustainable approach built on trust, not transactions. Artists create freely, fans contribute directly.
                    </p>

                    <p className="font-syne text-xl md:text-2xl max-w-3xl mx-auto mb-12">
                        Patronage sparked groundbreaking art for centuries. Now we&apos;re making this social contract accessible to all musicians—transparent, democratic, and built for today.
                    </p>                    

                    <div className="flex justify-center mb-64">
                        <CoralButton onClick={scrollToArtistProfileTools}>CREATE YOUR ARTIST PAGE</CoralButton>
                    </div>
                    <div className="py-0 sm:py-8 xl:py-24 3xl:py-80">
                    </div>
                </div>
            </section>

            {/* Ecosystem Approach Section */}
            <section className="bg-[#0C142D] text-white">
                <div className="py-8 md:py-16 mx-auto px-8 lg:px-16">
                    <div className="max-w-screen-xl mx-auto">
                        <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between">
                            <div className="lg:w-1/2 mb-8 lg:mb-0">
                                <h2 className="text-xl md:text-2xl font-extrabold mb-2 sm:mb-4">
                                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                        ECOSYSTEM APPROACH
                                    </span>
                                </h2>
                                <h3 className="font-syne text-2xl sm:text-3xl lg:text-4xl mb-0 sm:mb-8 leading-tight mr-0 sm:mr-12">
                                    Our approach encourages collaboration among artists and scenes. What benefits each, benefits all.
                                </h3>
                            </div>
                            <div className="lg:w-1/2 mb-6 sm:mb-14">
                                <CoralEcosystem />
                            </div>
                        </div>
                    </div>
                </div>
                <img src={blueWaveImage} alt="Blue Wave" className="w-full" />
            </section>

            {/* Coordinated Scale Section */}
            <section className="bg-coral-deep-blue text-white">
                <div className="max-w-screen-xl mx-auto px-8 lg:px-16">
                    <h2 className="text-xl md:text-2xl font-extrabold mb-4">
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                            COORDINATED SCALE
                        </span>
                    </h2>
                    <p className="text-xl mb-8">
                        Our platform enables communities to cooperate at scale. The math is compelling, explore the dynamics of scale below.
                    </p>
                    <CollectiveImpactTable />
                    <div className="flex justify-center mt-16">
                        <CoralButton onClick={scrollToArtistProfileTools}>CREATE YOUR ARTIST PAGE</CoralButton>
                    </div>
                </div>
                <div className="relative mt-16">
                    <img src={darkBlueWaveImage} alt="Dark Blue Wave" className="w-full" />
                    <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-dark-blue to-transparent"></div>
                </div>
            </section>



            {/* Next Steps Section */}
            <section className="bg-coral-dark-blue text-white pb-16">
                <div className="max-w-screen-xl mx-auto px-8 pb-8">
                    <h2 className="text-xl md:text-2xl font-extrabold mb-8">
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                            WHAT&apos;S NEXT?
                        </span>
                    </h2>
                    <ol className="space-y-4 relative mt-4 text-sm sm:text-base md:text-lg">
                        <div className="absolute left-2 top-1 bottom-0 w-1 bg-gradient-to-b from-coral-pink to-transparent"></div>
                        {[
                            "Use the tool above to create your Artist Page and assets",
                            "Share them with your community via Linktree, socials, and at gigs",
                            "When you receive your first contribution, we'll contact you via your official channels (Instagram, website, or manager) with a unique code",
                            "Visit the payment collection link, provide your email, unique code, and PayPal username to sign up and start receiving monthly payments"
                        ].map((step, index) => (
                            <li key={index} className="flex items-start">
                                <div className="bg-coral-pink rounded-full w-5 h-5 mr-4 flex justify-center items-center flex-shrink-0">
                                    <div className="rounded-full w-3 h-3"></div>
                                </div>
                                <span>{step}</span>
                            </li>
                        ))}
                    </ol>
                    <p className="text-sm sm:text-base md:text-lg mt-8">
                        Read our <a href="/artist-terms" rel="noopener noreferrer" className="text-coral-pink">Artist Terms and Conditions</a> for more details on how we can cooperate.
                    </p>
                </div>
                <div className="max-w-screen-xl mx-auto px-8 lg:px-16">
                    <div className="sm:mb-12">
                        <ArtistPayoutChecker />
                    </div>                
                </div>
            </section>

            {/* FAQ Section */}
            <section className="bg-coral-dark-blue text-white p-8 md:p-16 px-8 lg:px-16">
                <div className="max-w-screen-xl mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
                        <div>
                            <h2 className="text-xl md:text-2xl font-extrabold mb-4">
                                <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                    SUPPORT
                                </span>
                            </h2>
                            <h3 className="font-syne text-3xl md:text-4xl mb-6 leading-tight">
                                FAQs
                            </h3>
                            <p className="text-xl mb-8">
                                Everything you need to know about wearecoral and how it operates.
                            </p>
                        </div>
                        <div className="space-y-4">
                            {faqData.map((faq, index) => (
                                <FAQItem key={index} question={faq.question} answer={faq.answer} />
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* Final CTA Section */}
            <section className="bg-coral-dark-blue text-white p-16 px-8 lg:px-16">
                <div className="max-w-screen-xl mx-auto flex flex-col items-center">
                <h2 className="font-syne text-3xl md:text-3xl pb-6 text-center">
                        A vibrant, healthy, and diverse ecosystem of music, artists and communities.
                    </h2>
                    <div className="flex justify-center mt-8">
                        <CoralButton onClick={scrollToArtistProfileTools}>GENERATE YOUR PROFILE</CoralButton>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ForArtists;