import React from 'react';
import { Link } from 'react-router-dom';
import coralReef from '../../../assets/images/coral-reef.svg';
import blueWaveImage from '../../../assets/images/blue-wave.png';
import CoralEcosystem from '../../../components/CoralEcosystem/CoralEcosystem';

const NotFound: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Hero Section */}
      <section className="relative pt-8 sm:pt-16 md:pt-32 bg-no-repeat bg-contain bg-bottom xl:bg-cover" style={{ backgroundImage: `url(${coralReef})` }}>
        <div className="max-w-screen-xl mx-auto px-8">
          <div className="flex flex-col md:flex-row md:items-start md:justify-between gap-8 pb-32 sm:pb-32 md:pb-48 lg:pb-96 xl:pb-96">
            <div className="w-full">
              <h1 className="font-syne font-medium text-4xl sm:text-5xl md:text-6xl text-coral-black font-extrabold mb-4 leading-tight">
                Page Not Found
              </h1>
              <p className="text-lg sm:text-xl md:text-2xl font-syne text-coral-black mb-6">
                We couldn&apos;t find the page you&apos;s are looking for.
              </p>
              <Link 
                to="/"
                className="inline-block bg-gradient-to-r from-coral-pink to-coral-orange text-white font-bold py-3 px-6 rounded-md hover:opacity-90 transition-opacity"
              >
                Return Home
              </Link>
              <div className="py-8 sm:py-16 xl:py-24 3xl:py-48">
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="bg-coral-blue text-white">
        <div className="p-8 md:p-16">
          <div className="max-w-screen-xl mx-auto">
            <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between">
              <div className="lg:w-1/2 mb-8 lg:mb-0">
                <h2 className="text-xl md:text-2xl font-extrabold mb-2 sm:mb-4">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                    OUR VISION
                  </span>
                </h2>
                <h3 className="font-syne text-2xl md:text-4xl mb-0 sm:mb-8 leading-tight mr-0 sm:mr-12">
                  A thriving ecosystem where music is valued and diverse styles and scenes can prosper.
                </h3>
              </div>
              <div className="lg:w-1/2 mb-6 sm:mb-14">
                <CoralEcosystem />
              </div>
            </div>
          </div>
        </div>
        <img src={blueWaveImage} alt="Blue Wave" className="w-full" />
      </section>
    </div>
  );
};

export default NotFound;
