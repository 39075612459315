import React, { useState } from 'react';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import GenericCoralModal from '../GenericCoralModal/GenericCoralModal';
import EcosystemVisual from './EcosystemVisual';


const CoralEcosystem: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  

  const openModal = () => {
    setIsModalOpen(true);
    trackEvent(EventName.VIEW_ALL_CONTRIBUTIONS_INTERACTION, {});
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="bg-white bg-opacity-100 shadow-lg rounded-xl p-6 m-3 max-w-4xl mx-auto">
      <div className="flex flex-col items-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 items-center w-full">
          <div className="text-center sm:text-left">
            <h2 className="font-syne text-2xl sm:text-2xl lg:text-3xl xl:text-4xl font-semibold text-gray-800 mb-2 sm:mb-4">
              The Coral Ecosystem
            </h2>
            <p className="text-base sm:text-lg text-gray-600">
              Each coral contributes to create a thriving, diverse music ecosystem.
            </p>
          </div>
          <div className="w-full aspect-square flex items-center justify-center">
            <EcosystemVisual width={280} height={200} />
          </div>
        </div>
        <div className="mt-4 text-center">
          <p className="text-xs text-gray-400">
            Live visualisation of the coral ecosystem.
          </p>
          <button 
            onClick={openModal}
            className="mt-2 text-xs text-coral-pink hover:underline focus:outline-none"
          >
            Download all our data
          </button>
        </div>
      </div>
      <GenericCoralModal isOpen={isModalOpen} onClose={closeModal}>
        <div className="block md:hidden">
            <iframe 
            src="https://cdn.forms-content-1.sg-form.com/a7a097eb-4252-11ef-9b81-4a03a5e66761"
            title="Contributions Form (Mobile)"
            className="w-full h-full min-h-[550px]"
            />
        </div>
        <div className="hidden md:block">
            <iframe 
            src="https://cdn.forms-content-1.sg-form.com/ef7831be-41b0-11ef-86c0-3e26e4945dab"
            title="Contributions Form"
            className="w-full h-full min-h-[550px]"
            />
        </div>
      </GenericCoralModal>
    </div>
  );
};

export default CoralEcosystem;