import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';
import { getCurrentUser } from '@aws-amplify/auth';
import { getUserFromAPI } from '../api/api';
import App from '../App';
import PageLoader from '../components/PageLoader/PageLoader';

// Landing & Key Public Routes (eagerly loaded)
import Home from '../pages/public-landing/Home/Home';
import PublicCoral from '../pages/public-landing/PublicCoral/PublicCoral';
import PublicArtist from '../pages/public-landing/PublicArtist/PublicArtist';
import ForArtists from '../pages/public-landing/ForArtists/ForArtists';
import Welcome from '../pages/platform/Welcome/Welcome';
import NotFound from '../pages/public-supplementary/NotFound/NotFound';
import Payment from '../pages/public-landing/Payment/Payment';
import Thankyou from '../pages/public-landing/PublicArtist/Thankyou';
import SignOut from '../pages/platform/SignOut/SignOut';
import VerifyMagicLink from '../pages/platform/VerifyMagicLink/VerifyMagicLink';
import RequestLogin from '../components/RequestLogin/RequestLogin';
import ArtistPayoutCollection from '../pages/public-landing/PublicArtist/ArtistPayoutCollection';

// Static Public Pages (chunk: "public-static")
const StaticPages = {
  About: lazy(() => import(/* webpackChunkName: "public-static" */ '../pages/public-supplementary/About/About')),
  Contact: lazy(() => import(/* webpackChunkName: "public-static" */ '../pages/public-supplementary/Contact/Contact')),
  Privacy: lazy(() => import(/* webpackChunkName: "public-static" */ '../pages/public-policies/Privacy/Privacy')),
  Terms: lazy(() => import(/* webpackChunkName: "public-static" */ '../pages/public-policies/Terms/Terms')),
  ArtistTerms: lazy(() => import(/* webpackChunkName: "public-static" */ '../pages/public-policies/Terms/ArtistTerms')),
  Principles: lazy(() => import(/* webpackChunkName: "public-static" */ '../pages/public-supplementary/Principles/Principles')),
  Trust: lazy(() => import(/* webpackChunkName: "public-static" */ '../pages/public-supplementary/Trust/Trust'))
};

// Protected User Pages (chunk: "user")
const UserPages = {
  Dashboard: lazy(() => import(/* webpackChunkName: "user" */ '../pages/platform/Dashboard/Dashboard')),
  OurImpact: lazy(() => import(/* webpackChunkName: "user" */ '../pages/platform/OurImpact/OurImpact')),
  Settings: lazy(() => import(/* webpackChunkName: "user" */ '../pages/platform/Settings/Settings')),
  Coral: lazy(() => import(/* webpackChunkName: "user" */ '../pages/platform/Coral/Coral')),
  CreateCoralFunnel: lazy(() => import(/* webpackChunkName: "user" */ '../pages/platform/CreateCoralFunnel/CreateCoralFunnel')),
  EditCoral: lazy(() => import(/* webpackChunkName: "user" */ '../pages/platform/Coral/EditCoral')),
  UserIdentityVerification: lazy(() => import(/* webpackChunkName: "user" */ '../pages/platform/ArtistSetup/UserIdentityVerificationForm')),
  ArtistDetailedVerification: lazy(() => import(/* webpackChunkName: "user" */ '../pages/platform/ArtistSetup/ArtistDetailedVerification')),
  ArtistConnections: lazy(() => import(/* webpackChunkName: "user" */ '../pages/platform/ArtistSetup/ArtistConnections')),
  ProfileSetup: lazy(() => import(/* webpackChunkName: "user" */ '../pages/platform/ProfileSetup/ProfileSetup')),
};

// Admin Pages (chunk: "admin")
const AdminPages = {
  Landing: lazy(() => import(/* webpackChunkName: "admin" */ '../pages/platform-admin/Admin/AdminLandingPage')),
  MissingArtists: lazy(() => import(/* webpackChunkName: "admin" */ '../pages/platform-admin/Admin/Artist/MissingArtists/MissingArtists')),
  IdentityVerification: lazy(() => import(/* webpackChunkName: "admin" */ '../pages/platform-admin/Admin/Artist/Verification/IdentifyVerification')),
  ArtistApplication: lazy(() => import(/* webpackChunkName: "admin" */ '../pages/platform-admin/Admin/Artist/Verification/ArtistApplicationAdmin')),
  ArtistPayments: lazy(() => import(/* webpackChunkName: "admin" */ '../pages/platform-admin/Admin/Artist/ArtistPayments/ArtistPayments'))
};

async function authLoader() {
  try {
    const currentUser = await getCurrentUser();
    if (!currentUser) {
      return redirect('/');
    }
    const userProfile = await getUserFromAPI(currentUser.userId);
    if (!userProfile?.settings?.profileSetupComplete) {
      return redirect('/setup');
    }
    return { currentUser, userProfile };
  } catch (error) {
    return redirect('/');
  }
}

async function publicLoader() {
  try {
    const currentUser = await getCurrentUser();
    const userProfile = currentUser ? await getUserFromAPI(currentUser.userId) : null;
    return { currentUser, userProfile };
  } catch (error) {
    return { currentUser: null, userProfile: null };
  }
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFound />,
    children: [
      // Eagerly loaded public routes
      {
        path: '/',
        element: <Home />,
        loader: publicLoader,
      },
      {
        path: '/c/:id',
        element: <PublicCoral />,
        loader: publicLoader,
      },
      {
        path: '/a/:id',
        element: <PublicArtist />,
        loader: publicLoader,
      },
      {
        path: '/welcome',
        element: <Welcome />,
        loader: publicLoader,
      },
      {
        path: '/about',
        element: <Suspense fallback={<PageLoader />}><StaticPages.About /></Suspense>,
        loader: publicLoader,
      },
      {
        path: '/for-artists',
        element: <Suspense fallback={<PageLoader />}><ForArtists /></Suspense>,
        loader: publicLoader,
      },
      {
        path: '/privacy',
        element: <Suspense fallback={<PageLoader />}><StaticPages.Privacy /></Suspense>,
        loader: publicLoader,
      },
      {
        path: '/terms',
        element: <Suspense fallback={<PageLoader />}><StaticPages.Terms /></Suspense>,
        loader: publicLoader,
      },
      {
        path: '/contact-us',
        element: <Suspense fallback={<PageLoader />}><StaticPages.Contact /></Suspense>,
        loader: publicLoader,
      },

      // Additional public routes
      {
        path: '/payment/:id',
        element: <Payment />,
        loader: publicLoader,
      },
      {
        path: '/thankyou',
        element: <Thankyou />,
        loader: publicLoader,
      },
      {
        path: '/sign-out',
        element: <SignOut />,
        loader: publicLoader,
      },
      {
        path: '/auth/verify',
        element: <VerifyMagicLink />,
        loader: publicLoader,
      },
      {
        path: '/requestlogin',
        element: <RequestLogin />,
        loader: publicLoader,
      },
      {
        path: '/a/:artistUrl/collect',
        element: <ArtistPayoutCollection />,
        loader: publicLoader,
      },

      // Additional static pages
      {
        path: '/artist-terms',
        element: <Suspense fallback={<PageLoader />}><StaticPages.ArtistTerms /></Suspense>,
        loader: publicLoader,
      },
      {
        path: '/principles',
        element: <Suspense fallback={<PageLoader />}><StaticPages.Principles /></Suspense>,
        loader: publicLoader,
      },
      {
        path: '/trust',
        element: <Suspense fallback={<PageLoader />}><StaticPages.Trust /></Suspense>,
        loader: publicLoader,
      },

      // Account routes
      {
        path: '/dashboard',
        element: <Suspense fallback={<PageLoader />}><UserPages.Dashboard /></Suspense>,
        loader: authLoader,
      },
      {
        path: '/settings',
        element: <Suspense fallback={<PageLoader />}><UserPages.Settings /></Suspense>,
        loader: authLoader,
      },
      {
        path: '/setup',
        element: <Suspense fallback={<PageLoader />}><UserPages.ProfileSetup /></Suspense>,
        loader: authLoader,
      },
      {
        path: '/coral',
        element: <Suspense fallback={<PageLoader />}><UserPages.Coral /></Suspense>,
        loader: authLoader,
      },
      {
        path: '/impact',
        element: <Suspense fallback={<PageLoader />}><UserPages.OurImpact /></Suspense>,
        loader: authLoader,
      },
      {
        path: '/coral/:id',
        element: <Suspense fallback={<PageLoader />}><UserPages.Coral /></Suspense>,
        loader: authLoader,
      },

      // Additional protected routes
      {
        path: '/verify-identity',
        element: <Suspense fallback={<PageLoader />}><UserPages.UserIdentityVerification /></Suspense>,
        loader: authLoader,
      },
      {
        path: '/artist-applications',
        element: <Suspense fallback={<PageLoader />}><UserPages.ArtistDetailedVerification /></Suspense>,
        loader: authLoader,
      },
      {
        path: '/artist-connections',
        element: <Suspense fallback={<PageLoader />}><UserPages.ArtistConnections /></Suspense>,
        loader: authLoader,
      },
      {
        path: '/create-coral',
        element: <Suspense fallback={<PageLoader />}><UserPages.CreateCoralFunnel /></Suspense>,
        loader: authLoader,
      },
      {
        path: '/coral/:id/edit',
        element: <Suspense fallback={<PageLoader />}><UserPages.EditCoral /></Suspense>,
        loader: authLoader,
      },

      // Admin routes
      {
        path: '/admin/home',
        element: <Suspense fallback={<PageLoader />}><AdminPages.Landing /></Suspense>,
        loader: authLoader,
      },
      {
        path: '/admin/artist/payments',
        element: <Suspense fallback={<PageLoader />}><AdminPages.ArtistPayments /></Suspense>,
        loader: authLoader,
      },
      {
        path: '/admin/artist/missing',
        element: <Suspense fallback={<PageLoader />}><AdminPages.MissingArtists /></Suspense>,
        loader: authLoader,
      },
      {
        path: '/admin/artist/identity-verification',
        element: <Suspense fallback={<PageLoader />}><AdminPages.IdentityVerification /></Suspense>,
        loader: authLoader,
      },
      {
        path: '/admin/artist/applications',
        element: <Suspense fallback={<PageLoader />}><AdminPages.ArtistApplication /></Suspense>,
        loader: authLoader,
      },
    ],
  },
]);