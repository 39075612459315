import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import coralReef from '../../../assets/images/coral-reef.svg';
import blueWaveImage from '../../../assets/images/blue-wave.png';
import darkBlueWaveImage from '../../../assets/images/dark-blue-wave.png';
import { faqData } from '../Home/faqData';
import Spinner from '../../../components/Spinner/Spinner';
import { storeReferralUrl } from '../../../utils/referralUtils';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { BlendedArtistListItem } from 'shared/types/platformTypes';
import { getArtistDetailsPublic } from '../../../api/api';
import AllInOneCoralCreator from '../../../components/AllInOneCoralCreator/AllInOneCoralCreator';
import FAQItem from '../../../components/FAQItem/faqItem';
import CollectiveImpactCalculator from '../../../components/CollectiveImpactTable/CollectiveImpactCalculator';
import CoralEcosystem from '../../../components/CoralEcosystem/CoralEcosystem';
import { Tooltip } from 'react-tooltip';
import CoralButton from '../../../components/CoralButtons/CoralButton';
import MetaHead from '../../../components/MetaHead/MetaHead';
import EcosystemVitals from '../../../components/EcosystemVitals/EcosystemVitals';

interface EcosystemNote {
  creatorName: string;
  message: string;
  monthsSupporting: number;
  monthlyAmount: number;
  createdAt: string;
}

interface ArtistEcosystemData extends BlendedArtistListItem {
  ecosystemSize: number;
  totalSupporters: number;
  monthlySupport: number;
  retentionRate: number;
  ecosystemAge: number;
  ecosystemStage: 'Emerging' | 'Growing' | 'Established' | 'Thriving';
  ecosystemHealth: number;
  featuredNote: EcosystemNote;
  recentNotes: EcosystemNote[];
}

const PublicArtistLanding: React.FC = () => {
  const { id } = useParams();
  const location = useLocation();
  const [artistData, setArtistData] = useState<ArtistEcosystemData | null>(null);

  const scrollToTop = useCallback(() => {
    console.log("Scroll function called");
    setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100); // Delay to ensure DOM is fully rendered
}, []);
  
  const mockEcosystemData: Partial<ArtistEcosystemData> = {
    ecosystemSize: 342,
    totalSupporters: 342,
    monthlySupport: 2890,
    retentionRate: 94,
    ecosystemAge: 8,
    ecosystemStage: 'Growing',
    ecosystemHealth: 82,
    featuredNote: {
      creatorName: 'Alex Thompson',
      message: "SAFIA's music has been the soundtrack to some of my biggest moments. Supporting them monthly feels like being part of something bigger than just streaming their music.",
      monthsSupporting: 6,
      monthlyAmount: 10,
      createdAt: '2024-01-15T10:00:00Z'
    },
    recentNotes: [
      {
        creatorName: 'Sarah Chen',
        message: "Their latest album got me through a tough time. Glad I can give back.",
        monthsSupporting: 3,
        monthlyAmount: 5,
        createdAt: '2024-01-18T10:00:00Z'
      },
      {
        creatorName: 'Marcus Wright',
        message: "Been following since day one. The ecosystem here feels so much more meaningful than just likes and follows.",
        monthsSupporting: 8,
        monthlyAmount: 15,
        createdAt: '2024-01-17T10:00:00Z'
      }
    ]
  };

  useEffect(() => {
    if (id) {
      const referralUrl = `${window.location.origin}${location.pathname}`;
      storeReferralUrl(referralUrl);
      fetchArtistData(id);
    }
  }, [id, location.pathname]);

  const fetchArtistData = async (artistUrl: string) => {
    try {
      const data = await getArtistDetailsPublic(artistUrl, undefined, undefined, true);
      setArtistData({
        ...mockEcosystemData,
        ...data[0]
      } as ArtistEcosystemData);
      trackEvent(EventName.PUBLIC_ARTIST_VIEW, { artistUrl, artistName: data[0].artistName! });
    } catch (error) {
      console.error('Error fetching artist data:', error);
    }
  };

  if (!artistData) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <MetaHead 
        title={`Back ${artistData?.artistName || 'Artist'} with Coral`}
        description={`Join ${artistData.ecosystemSize} others backing ${artistData?.artistName || 'this artist'} with Coral`}
      />


{/* Hero Section */}
<section className="relative pt-4 sm:pt-8 md:pt-16 bg-no-repeat bg-contain bg-bottom 2xl:bg-cover" style={{ backgroundImage: `url(${coralReef})` }}>
  <div className="max-w-screen-xl mx-auto px-8 sm:mb-64 3xl:mb-96">
    {/* Full Width Header */}
    <h1 className="font-syne font-medium text-4xl sm:text-5xl md:text-6xl font-extrabold mb-8 leading-tight">
      <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
        {artistData.artistName}
      </span>
    </h1>

    {/* Two Column Layout */}
    <div className="flex flex-col md:flex-row md:items-start md:justify-between gap-8">
      <div className="md:w-1/2">
        <p className="text-lg sm:text-xl md:text-2xl font-syne text-coral-black py-2 mb-6">
          {artistData.artistName}{artistData.artistName!.endsWith('s') ? '\'' : '\'s'} music moves you. Join others in expressing that with corals, giving them more freedom to focus on what matters - music.
        </p>

        {/* Value Props */}
        <div className="space-y-6 mb-6">
          <div>
            <h3 className="text-lg sm:text-xl md:text-2xl font-syne font-semibold text-coral-black mb-2">Join a Living Ecosystem</h3>
            <ul className="text-base sm:text-lg md:text-xl font-syne font-medium text-coral-black space-y-2">
              <li className="flex items-start gap-2">
                <span className="text-coral-pink mt-1">•</span>
                Create a connection through reciprocal backing
              </li>
              <li className="flex items-start gap-2">
                <span className="text-coral-pink mt-1">•</span>
                Be part of a community that values {artistData.artistName}&apos;s art
              </li>
              <li className="flex items-start gap-2">
                <span className="text-coral-pink mt-1">•</span>
                Receive regular updates on their journey
              </li>
            </ul>
            <div className="text-sm text-gray-400 mt-4">
              <span 
                className="cursor-help" 
                data-tooltip-id="compareTooltip" 
                data-tooltip-html="wearecoral: 7.75% fee<br />Bandcamp: 10%-15% fee<br />Patreon: 8%-12% fee<br />Merchandise: 40-60% to artist<br />Streaming: <1% to artist per play"
              >
                + more of your monthly contribution reaches artists than on <span className="underline">other platforms</span>
              </span>
              <Tooltip id="compareTooltip" className="z-50" />
            </div>                  
          </div>
        </div>

        {/* Ecosystem Vitals */}
        <div className="mb-6">
          <EcosystemVitals artistName={artistData.artistName || ''} />
        </div>
      </div>

      <div className="md:w-1/2">
        <AllInOneCoralCreator artistId={artistData.artistId} />
        {/* Featured Community Note */}
        {/* <div className="bg-white/30 backdrop-blur-sm rounded-lg p-8">
          <p className="italic mb-2 text-coral-black">&ldquo;{artistData.featuredNote.message}&rdquo;</p>
          <div className="flex justify-between text-sm text-coral-black">
            <p>{artistData.featuredNote.creatorName}</p>
            <p>Supporting for {artistData.featuredNote.monthsSupporting} months</p>
          </div>
        </div> */}
      </div>
    </div>
  </div>
</section>

      {/* Mission Section */}
      <section className="bg-coral-blue text-white">
        <div className="p-8 md:p-16">
          <div className="max-w-screen-xl mx-auto">
            <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between">
              <div className="lg:w-1/2 mb-8 lg:mb-0">
                <h2 className="text-xl md:text-2xl font-extrabold mb-2 sm:mb-4">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                    OUR VISION
                  </span>
                </h2>
                <h3 className="font-syne text-2xl md:text-4xl mb-0 sm:mb-8 leading-tight mr-0 sm:mr-12">
                  A thriving ecosystem where music is valued and diverse styles and scenes can prosper.
                </h3>
              </div>
              <div className="lg:w-1/2 mb-6 sm:mb-14">
                <CoralEcosystem />
              </div>
            </div>
          </div>
        </div>
        <img src={blueWaveImage} alt="Blue Wave" className="w-full" />
      </section>

      {/* How It Works Section */}
      <section className="bg-coral-deep-blue text-white">
        <div className="max-w-screen-xl mx-auto px-8 sm:px-6 lg:px-8">
          <h2 className="text-xl md:text-2xl font-extrabold mb-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
              CORAL DYNAMICS
            </span>
          </h2>
          <CollectiveImpactCalculator />
          <div className="flex justify-center mt-16">
            <CoralButton 
              onClick={scrollToTop} 
              className="text-lg px-8"
              popOnHover
            >
              Build Your Coral with {artistData.artistName}
            </CoralButton>
          </div>
        </div>
        <div className="relative mt-16">
          <img src={darkBlueWaveImage} alt="Dark Blue Wave" className="w-full" />
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-dark-blue to-transparent"></div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="bg-coral-dark-blue text-white p-8 md:p-16">
        <div className="max-w-screen-xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            <div>
              <h2 className="text-xl md:text-2xl font-extrabold mb-4">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                  SUPPORT
                </span>
              </h2>
              <h3 className="font-syne text-3xl md:text-4xl mb-6 leading-tight">
                FAQs
              </h3>
              <p className="text-xl mb-8">
                Everything you need to know about backing {artistData.artistName} through wearecoral.
              </p>
            </div>
            <div className="space-y-4">
              {faqData.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          </div>
          <div className="flex justify-center mt-16">
              <CoralButton 
                onClick={scrollToTop}
                className="text-lg px-8"
                popOnHover
              >
                Start Your Coral with {artistData.artistName}
              </CoralButton>
            </div>
        </div>
      </section>
    </div>
  );
};

export default PublicArtistLanding;