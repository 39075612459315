import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { useAuthContext } from '../../../components/Auth/AuthContext/AuthContext';
import coralReef from '../../../assets/images/coral-reef.svg';
import blueWaveImage from '../../../assets/images/blue-wave.png';
import CoralEcosystem from '../../../components/CoralEcosystem/CoralEcosystem';

const Thankyou: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userProfile } = useAuthContext();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const coralId = searchParams.get('coralId');

    trackEvent(EventName.CORAL_CREATION_COMPLETED, {
      userId: userProfile?.userId,
      coralId: coralId || undefined,
    });
  }, [location, userProfile]);

  return (
    <div className="min-h-screen flex flex-col">
      {/* Hero Section */}
      <section className="relative pt-8 sm:pt-16 md:pt-32 bg-no-repeat bg-contain bg-bottom xl:bg-cover" style={{ backgroundImage: `url(${coralReef})` }}>
        <div className="max-w-screen-xl mx-auto px-8">
          <div className="flex flex-col md:flex-row md:items-start md:justify-between gap-8 pb-32 sm:pb-32 md:pb-48 lg:pb-96 xl:pb-96">
            <div className="w-full">
              <h1 className="font-syne font-medium text-4xl sm:text-5xl md:text-6xl text-coral-black font-extrabold mb-4 leading-tight">
                Thank You for Creating Your Coral
              </h1>
              <p className="text-lg sm:text-xl md:text-2xl font-syne text-coral-black mb-2">
                Let&apos;s nurture a thriving and diverse ecosystem together.
              </p>
              <p className="text-lg sm:text-xl md:text-2xl font-syne text-coral-black">
                {userProfile 
                  ? "Your coral has been created successfully!"
                  : "Now check your email for a magic link to sign in to wearecoral."}
              </p>
              {userProfile && (
                <button
                  onClick={() => navigate('/dashboard')}
                  className="mt-6 px-6 py-3 bg-coral-pink text-white rounded-lg hover:bg-coral-pink-dark transition-colors"
                >
                  Return to Dashboard
                </button>
              )}
              <div className="py-8 sm:py-16 xl:py-24 3xl:py-48">
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="bg-coral-blue text-white">
        <div className="p-8 md:p-16">
          <div className="max-w-screen-xl mx-auto">
            <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between">
              <div className="lg:w-1/2 mb-8 lg:mb-0">
                <h2 className="text-xl md:text-2xl font-extrabold mb-2 sm:mb-4">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                    OUR VISION
                  </span>
                </h2>
                <h3 className="font-syne text-2xl md:text-4xl mb-0 sm:mb-8 leading-tight mr-0 sm:mr-12">
                  A thriving ecosystem where music is valued and diverse styles and scenes can prosper.
                </h3>
              </div>
              <div className="lg:w-1/2 mb-6 sm:mb-14">
                <CoralEcosystem />
              </div>
            </div>
          </div>
        </div>
        <img src={blueWaveImage} alt="Blue Wave" className="w-full" />
      </section>
    </div>
  );
};

export default Thankyou;